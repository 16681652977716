import React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';

const StyledTitle = styled.h2`
  color: ${({ color }) => theme.colors[color]};
  font-size: 4.8rem;
  font-weight: 800;
  line-height: 1;
  white-space: pre-line;
  margin-bottom: var(--spacing-small);

  @media (min-width: ${() => theme.breakPoints.large}) {
    font-size: 6rem;
  }

  @media (min-width: ${() => theme.breakPoints.xlarge}) {
    font-size: 7.2rem;
  }

  @media (min-width: ${() => theme.breakPoints.xlarge}) {
    font-size: 8.8rem;
  }
`;

export default function Title({ text, color, className }) {
  return (
    <StyledTitle className={className} color={color}>
      {text}
    </StyledTitle>
  );
}
