import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Div100vh from 'react-div-100vh';
import Title from '../components/Title';
import Section from '../components/Section';
import ContentWrapper from '../components/ContentWrapper';
import { theme } from '../../theme';

const Wrapper = styled(ContentWrapper)`
  height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    font-weight: 800;
  }
  span {
    margin-right: 1rem;
  }
`;

const StyledDiv100vh = styled(Div100vh)`
  @media (min-width: ${() => theme.breakPoints.small}) {
    height: 75vh !important;
  }

  @media (min-width: ${() => theme.breakPoints.xlarge}) {
    height: 40vh !important;
  }
`;

export default function FourOhFour() {
  return (
    <Section>
      <StyledDiv100vh>
        <Wrapper>
          <Title text="Page not found" />
          <p>
            Sorry, we couldn’t find what you were looking for.
            <br />
            <br />
            <Link to="/">
              <span>&larr;</span>
              Go home
            </Link>
          </p>
        </Wrapper>
      </StyledDiv100vh>
    </Section>
  );
}
